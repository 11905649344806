.content-img-wrapper {
    position: fixed;
    top: 8rem;
    left: calc(((100vw - 1150px) / 2) + var(--section-left-margin));
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;

    width: fit-content;
    height: fit-content;
}

.content-img-wrapper > img {
    width: 350px;
}
.content-img-wrapper > img:first-child {
    width: 250px;
    margin-left: calc((350px - 250px) / 2);
    cursor: pointer;
}

@media (max-height: 700px) {
    .content-img-wrapper > img:first-child {
        /* margin-left: calc((250px - 250px) / 2); */
        margin-left: 50px;
    }
    .content-img-wrapper > img:last-child {
        width: 250px;
        margin-left: 50px;
    }
}
@media (max-height: 500px) {
    .content-img-wrapper > img:first-child {
        width: 200px;
    }
    .content-img-wrapper > img:last-child {
        width: 200px;
    }
}

@media (max-width: 1050px) {
    .content-section {
        padding-top: 5rem;
    }

    .content-img-wrapper {
        top: 3rem;
        left: 10%;
    }

    .content-img-wrapper > img:first-child,
    .content-img-wrapper > img:last-child {
        margin: 0;
    }
}

@media (max-width: 779px) {
    .content-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;

        padding-top: 0;
    }

    .content-img-wrapper {
        position: static;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0;
        width: 100%;
    }

    
}

@media (max-width: 479px) {
    .content-section {
        padding: 2rem;
    }
}