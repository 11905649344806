.text-container {
    max-width: 560px;
    min-height: calc(100vh - 4rem);
    margin-left: auto;
}

.text-container > h1 {
    margin-bottom: 2rem;
    font-size: 1.5em;
    font-weight: 600;
}
.text-container > h2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1.4em;
    font-weight: 600;
}
.text-container > h3 {
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    font-size: 1.3em;
    font-weight: 600;
}
.text-container > h4 {
    margin-top: 1.5rem;
    font-size: 1.2em;
    font-weight: 600;
}
.text-container > p {
    margin: 1rem 0;
    font-size: var(--paragraph-font-size);
    font-weight: 300;
    line-height: var(--line-height);
}
.text-container > p > b,
.text-container > p > i > b {
    font-size: 1em;
    font-weight: 600;
}
.text-container > p > i {
    font-size: 1em;
}

.text-container > ul {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
}
.text-container > ol {
    margin-top: 1rem;
    margin-left: 2rem;
    margin-bottom: 1rem;
}
.text-container > ul > li,
.text-container > ol > li {
    font-size: var(--paragraph-font-size);
    font-weight: 300;
    line-height: var(--line-height);
}
.text-container > ul > li > p,
.text-container > ol > li > p {
    font-size: 1em;
    font-weight: 300;
    line-height: var(--line-height);
}
.text-container > ul > li > b,
.text-container > ol > li > b {
    font-size: 1em;
    font-weight: 600;
}
.text-container > ul > li > h4,
.text-container > ol > li > h4 {
    margin-top: 1.5rem;
    font-size: 1em;
    font-weight: 600;
}
.nested-list {
    margin-left: 1rem;
}
.nested-list > li {
    font-size: 1.3em;
    font-weight: 300;
    line-height: var(--line-height);
}
.nested-list > li > b {
    font-size: 1em;
    font-weight: 600;
}

.restoration-list > li {
    margin-bottom: 1rem;
}

.text-img-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
}
.text-img-container-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
}

.text-img-container > img,
.text-img-container-row > img {
    width: 50%;
}
.text-img-container > .small-img,
.text-img-container-row > .small-img {
    width: 50%;
}
.text-img-container > .medium-img,
.text-img-container-row > .medium-img {
    width: 75%;
}
.text-img-container > .large-img,
.text-img-container-row > .large-img {
    width: 100%;
}
.text-img-container > .double-img,
.text-img-container-row > .double-img {
    width: calc(50% - 1rem);
}
.text-img-container > figcaption,
.text-img-container-row > figcaption {
    font-style: italic;
    text-align: center;
}

.paragraph-list {
    margin-top: 1rem;
}
.paragraph-list > p {
    font-size: var(--paragraph-font-size);
    font-weight: 300;
    line-height: var(--line-height);
}
.paragraph-list > p > b {
    font-size: 1em;
    line-height: var(--line-height);
}

.table {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    width: 50%;
    margin: 2rem auto;
    text-align: center;
}
.table > .table-head {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}
.table > .bold {
    font-weight: 600;
}
.table > span {
    font-size: var(--paragraph-font-size);
}

#horizontal-palette {
    max-height: 100px;
}

#lineover {
    text-decoration: line-through;
}

#blog-link {
    font-size: 1em;
    text-decoration: none;
    color: goldenrod;
}

@media (min-width: 780px) and (max-width: 1050px) {
    .text-container {
        max-width: 55%;
    }
}

@media (max-width: 779px) {
    .text-container {
        width: 100%;
        margin: 0;
    }
}