header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    /* display: flex;
    justify-content: space-between;
    align-items: flex-end; */

    width: 100%;
    /* height: var(--header-height); */
}


nav {
    position: relative;
    max-width: 1150px;
    height: var(--header-height);
    margin: 0 auto;
    transition: 1s;
}

nav>ul {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;

    display: flex;
    align-items: flex-end;
    gap: 0.5rem;

    width: fit-content;
    height: calc(var(--header-height) + 3.5rem);
    margin-left: auto;
    margin-right: 2.5rem;
    /* padding-bottom: 2rem; */

    background-color: #000000;
    list-style-type: none;

    transition: 0.5s;
}

nav>ul>li {
    flex-grow: 1;
    margin-bottom: 4rem;
}

nav>ul>li::after {
    content: "|";
    margin-left: 0.5rem;
}

nav>ul>li:last-child::after {
    content: "";
}

nav>ul>li>a {
    text-decoration: none;
}

nav>ul>li>a:hover {
    color: #ffffff;
}

.nav-button {
    background-color: transparent;
    border: none;
    font-size: 1em;
    cursor: pointer;
}

.nav-button:hover {
    color: #ffffff;
}


.hidden-menu {
    opacity: 0;
}
/* Nav btns are transparent in landing page until user clicks */
.transparent {
    background-color: transparent;
}
.hidden-list {
    opacity: 0;
}

.active-link {
    color: #ffffff;
}
.dropdown-content > .active-sub-link {
    color: #ffffff;
}

/* Dropdown menu */



.dropdown-content {
    position: absolute;
    z-index: 10;
    max-height: 70vh;
    padding-top: 0.5rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    min-width: 175px;
}
.dropdown:last-of-type > .dropdown-content {
    right: 0;
}

.dropdown-content > a {
    padding: 1rem;
    background-color: #1c1c1c;
    color: var(--font-color);
    text-decoration: none;
    font-size: 0.9em;
    border-bottom: 1px solid #3f3f3f;
}

.dropdown-content > a:last-child {
    padding-bottom: 1rem;
    border-bottom: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.dropdown-content > a:hover {
    color: #ffffff;
}

.responsiveMenuOff {
    transform: translateX(-100%);
}

.dropdown-content > div {
    padding: 1rem;
}


@media (max-height: 800px) {
    nav > ul {
        height: calc(var(--header-height) + 2rem);
    }
}
@media (max-height: 650px) {
    nav > ul {
        height: calc(var(--header-height) + 1rem);
    }
}

@media (max-width: 1050px) {
    /* Hamburger btn */
    .hamburger-container {
        position: fixed;
        top: 25px;
        left: 25px;
        z-index: 11;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 7px;
        align-items: center;
        width: 30px;
    }
    .hamburger-line {
        width: 100%;
        height: 3px;
        background-color: #ffffff;
        transition: 0.5s;
    }
    .close-hamburger > .hamburger-line:first-child {
        position: absolute;
        top: 10px;
        width: 80%;
        transform: rotate(225deg);
    }
    .close-hamburger > .hamburger-line:nth-of-type(2) {
        display: none;
    }
    .close-hamburger > .hamburger-line:last-child {
        position: absolute;
        top: 11px;
        width: 80%;
        transform: rotate(-225deg);
    }

    header {
        position: fixed;
        top: 0;
        left: 0;

        width: 300px;
        height: 100vh;
        padding: 6rem 3rem;

        background-color: #000000;
        transition: 0.5s;
        overflow: auto;
    }

    nav {
        width: 100%;
        height: 100%;
    }

    nav > ul {
        position: static;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0;

        width: 100%;
        height: fit-content;
        margin: 0;
        padding: 0;

        background-color: transparent;
        overflow: auto;
    }

    nav > ul > li {
        flex-grow: 0;
        width: 100%;
        margin: 0;
    }
    nav > ul > li::after {
        display: none;
    }

    nav > ul > li > a {
        display: block;
        padding: 1.5rem 0;
        border-bottom: 1px solid #3f3f3f;
        font-size: 1em;
    }
    nav > ul > li:last-child > a {
        border: none;
    }
    /* In mobile menu, dropdown list pushes down the rest of the nav items */
    .dropdown-content {
        position: static;
        max-height: fit-content;
    }

    .dropdown-content > a {
        font-size: 1em;
    }

    /* In mobile, when you open the menu, you can see where are you beacuse the dropdown content of the page you are in is displayed */
    .active-link ~ .dropdown-off {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 479px) {
    .hamburger-container {
        top: 20px;
        left: 20px;
        justify-content: center;
        width: 40px;
        min-height: 40px;
        gap: 4px;
        padding: 10px;
    }
    .hamburger-container > div {
        height: 2px;
    }
    .close-hamburger > .hamburger-line:first-child {
        top: 18px;
        width: 20px;
    }
    .close-hamburger > .hamburger-line:last-child {
        top: 18px;
        width: 20px;
    }
}