.hero-section {
    position: relative;
    width: 100%;
    height: 100vh;

    cursor: pointer;
}

#title {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
}

.logo-container {
    position: absolute;
    top: 12rem;
    left: 5rem;
    z-index: 2;
    width: 300px;
}
.logo-container > img {
    width: 100%;
}

.hero-img-container {
    position: absolute;
    top: 0;
    right: 20rem;
    height: 100%;
}
.hero-img-container > img {
    height: 100%;
    max-height: 800px;
}

@media (max-width: 1050px) {
    .hero-section {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5rem;

        max-width: 100vw;
        padding: 0 10%;
    }

    .hero-section > div {
        position: static;
    }
    .logo-container {
        margin-top: 20vh;
        min-width: 250px;
    }
}

@media (max-width: 479px) {
    .hero-section {
        flex-direction: column;
        align-items: center;
        gap: 0;
        padding: 2rem 0 0;
    }
    .hero-mobile-img {
        max-width: 100%;
        max-height: 100vh;
    }
}