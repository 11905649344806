#not-found-h2 {
    margin: 8rem auto 2rem auto;
    text-align: center;
    font-size: 3em;
    font-weight: 600;
}
#not-found-p {
    margin: 2rem auto;
    text-align: center;
    font-size: 1.8em;
    font-weight: 600;
}
#not-found-link {
    margin: 2rem auto;
    text-align: center;
}
#not-found-link > a {
    display: inline-block;
    padding: 1rem 1.5rem;
    background-color: white;
    color: black;
    text-decoration: none;
    font-size: 1.5em;
    border-radius: 4px;
    cursor: pointer;
}