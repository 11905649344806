.cookies-banner-container {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    padding: 1rem;
    background-color: black;

    z-index: 999;
}

.cookies-banner-container > button {
    background-color: black;
    border: none;
    cursor: pointer;
}

.hidden-banner {
    display: none;
}