:root {
    /* Sizes */
    --header-height: 9rem;
    --section-top-margin: calc(var(--header-height) + 7.5rem);
    --section-right-margin: 5rem;
    --section-bottom-margin: 5rem;
    --section-left-margin: 5rem;
    --content-img-width: 350px;
    --paragraph-font-size: 1.3em;
    --line-height: 1.5em;

    /* Colors */
    --font-color: #bfbfbf;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Open Sans", Helvetica, Arial, Verdana, sans-serif;
    font-size: 12px;
    letter-spacing: 0.75px;
    color: var(--font-color);
    /* Prevent selecting text */
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

body {
    width: calc(100vw - (100vw - 100%));
    background-color: #000000;
}

section {
    max-width: 1150px;
    margin: 0 auto;
    padding: var(--section-top-margin) var(--section-right-margin) var(--section-bottom-margin) var(--section-left-margin);
}